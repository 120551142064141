@import '../../styles/variables';

.pageInput {
  width: 5rem !important;
}

.rowSelectDropdown {
  width: unset !important;
}

.tableButtons {
  width: 3.5rem !important;

  @include media-breakpoint-down(xs) {
    width: unset !important;
  }
}

.controls {
  font-size: 0.875rem;

  @include media-breakpoint-down(sm) {
    font-size: 0.813rem;
  }
}
