// Bootstrap
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';
// @import '~bootstrap/scss/root';
// @import '~bootstrap/scss/bootstrap-reboot';
// // @import "~bootstrap/scss/type";
// // @import "~bootstrap/scss/images";
// // @import "~bootstrap/scss/code";

// $theme-colors: (
//   blue: #375a7f,
//   primary: #375a7f,
//   danger: #e74c3c,
//   info: #3498db,
//   secondary: #444,
//   success: #00bc8c,
//   warning: #f39c12,
//   light: #999,
//   dark: #303030,
// );

// @import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/tables';
// @import '~bootstrap/scss/forms';
// @import '~bootstrap/scss/buttons';
// @import '~bootstrap/scss/transitions';
// @import '~bootstrap/scss/dropdown';
// // @import "~bootstrap/scss/button-group";
// @import '~bootstrap/scss/input-group';
// @import '~bootstrap/scss/custom-forms';
// @import '~bootstrap/scss/nav';
// @import '~bootstrap/scss/navbar';
// // @import "~bootstrap/scss/card";
// // @import "~bootstrap/scss/breadcrumb";
// // @import "~bootstrap/scss/pagination";
// // @import "~bootstrap/scss/badge";
// // @import "~bootstrap/scss/jumbotron";
// @import '~bootstrap/scss/alert';
// // @import "~bootstrap/scss/progress";
// // @import "~bootstrap/scss/media";
// @import '~bootstrap/scss/list-group';
// // @import "~bootstrap/scss/close";
// // @import "~bootstrap/scss/toasts";
// @import '~bootstrap/scss/modal';
// // @import "~bootstrap/scss/tooltip";
// // @import "~bootstrap/scss/popover";
// // @import "~bootstrap/scss/carousel";
// @import '~bootstrap/scss/spinners';
// @import '~bootstrap/scss/utilities';
// // @import "~bootstrap/scss/print";

// @import '~react-image-lightbox/style.css';
// @import '~react-toastify/dist/ReactToastify.css';

// @import './styles/utils.scss';
// @import './styles/fonts.scss';
// @import './styles/helpers';
// @import './styles/spacing';
// @import './styles/styles.scss';

html,
body,
#root {
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

// Required
@import 'styles/variables';

// Optional
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';

// Custom
@import 'styles/utils';
@import 'styles/fonts';
@import 'styles/helpers';
@import 'styles/colors';
@import 'styles/spacing';
@import 'styles/styles';

// Styling for toasts
@import '~react-toastify/dist/ReactToastify.css';

// Styles for react-image-lightbox
@import '~react-image-lightbox/style.css';
