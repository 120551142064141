@import '../../styles/variables';

.descSortTableHeaderBorder {
  border-bottom: 4px solid $black !important;
}

.ascSortTableHeaderBorder {
  border-top: 4px solid $black !important;
}

.header {
  background-color: $gray-200;
  border-bottom: solid 1px black;
}
