// TODO: Come back to later to see if we can remove file.

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main {
  margin: 80px 0;
}

.image {
  display: block;
  max-width: 100%;
}

.show-more-btn {
  position: relative;
  top: -5px;
  display: block;
  margin-left: auto;
  border: none;
  appearance: none;
  background: none;
  font-size: 13px;
  padding: 0;
  cursor: pointer;
  color: $primary;

  &:hover {
    text-decoration: underline;
  }
}

.textarea-placeholder {
  min-height: 3.56em;
}

.rt-thead {
  font-weight: bold;
}

.rbt-close {
  @extend .no-btn-styles;
}

.message {
  &--error {
    color: $danger;
  }
}

.form-grid {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  &:empty {
    border: none;
  }

  &--no-border {
    border: none;
  }

  &--full-height {
    height: 100%;
  }

  &__row {
    display: flex;
    flex: 1;

    &:not(:last-of-type) {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);

      .form-grid--no-border & {
        border-bottom: none;
      }
    }
  }

  &__col {
    flex: 0 1 100%;
    padding: 7px 5px;

    .form-grid--no-border & {
      padding: 5px 0;
    }

    &:not(:last-of-type) {
      border-end: solid 1px rgba(0, 0, 0, 0.05);

      .form-grid--no-border & {
        border-end: none;
      }
    }

    &--125 {
      max-width: 125px;
    }
  }
}

// TODO: This is currently impacting the platform limits table on the contract detail page.
// If we want these styles we should move these styles to the component's SCSS file
// so we are not overriding Bootstrap table styles.
// Matching Bootstrap's bordered table styles with react-table
// .table.table-sm.table-bordered {
//   th,
//   td {
//     &:not(:last-of-type) {
//       border-end: solid 1px rgba(0, 0, 0, 0.05);
//     }
//   }

//   tr {
//     &:not(:last-of-type) td {
//       border-bottom: solid 1px rgba(0, 0, 0, 0.05);
//     }
//   }

//   thead {
//     box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
//   }

//   th {
//     border-bottom: none;
//   }

//   td {
//     height: 36px;
//   }
// }

.alert button.close,
.modal button.close {
  color: $primary;
  appearance: none;
  border: none;
  background: transparent;
  font-size: 1.5rem;
  padding: 10px 15px;
  font-weight: 700;
  line-height: 1;
  border-radius: 7px;

  &:hover {
    color: darken($primary, 10%);
  }
}

.confirm {
  .modal-body {
    font-size: 20px;
    line-height: 1.3;
  }

  .modal-footer {
    border-top: none;
  }
}

.custom-file-label {
  height: 33px;
  z-index: 5;
  cursor: pointer;

  &:hover {
    &:after {
      background-color: darken($primary, 10%);
      border-color: darken($primary, 10%);
    }
  }

  &:after {
    height: 31px;
    background-color: $primary;
    border-color: $primary;
    color: #fff;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $lightGray;
}

.modal.show {
  z-index: 1100 !important;
}

h5.modal-title {
  font-size: 1.3em;
  line-height: 1;
}

.form-group {
  margin-bottom: 10px;

  label {
    margin-bottom: 3px;
  }
}

.reset-btn {
  text-decoration: underline;
  color: $gray;
  padding: 0 5px;
}

.page-buttons {
  display: flex;
  min-height: 32px;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

a,
.btn-link {
  color: $linkColor;
  &:hover {
    color: darken($linkColor, 15%);
  }
}

label {
  font-weight: 500;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $primary;
  background-color: $primary;
}

.no-shadow {
  box-shadow: none !important;
}

label {
  font-weight: normal;
  margin-bottom: 0;
}
