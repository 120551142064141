@import 'styles/utils.scss';

.header {
  .navbar-nav {
    @media screen and (min-width: 768px) {
      width: 100%;
    }
  }

  .firstnav {
    padding: 80px;
    a.nav-link {
      color: #fff;
    }
  }

  .secondnav {
    background-color: $gray-200;
  }

  a.nav-link {
    font-size: calc(0.5rem + 0.5vw);
    padding: 5px;

    &.active {
      font-weight: bold;
      background-color: $gray-200;
      color: $primary;
    }
  }

  .menu {
    background-color: $gray-200;

    a.nav-link {
      font-size: calc(0.75rem + 0.5vw);

      &.active {
        font-weight: bold;
        background-color: transparent !important;
      }
    }
  }

  .dropdown-item a.nav-link {
    font-size: 14px !important;
  }
}
