// TODO: Come back to later to see if we can remove file.

.danger {
  color: $danger;
}

.primary {
  color: $primary;
}

.success {
  color: $success;
}

.black {
  color: $black;
}

.white {
  color: #fff !important;
}
