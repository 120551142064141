// TODO: Come back to later to see if we can remove file.

.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-a {
  margin-top: auto;
}

.mb-3px {
  margin-bottom: 3px;
}
