@import '../../styles/variables';

.input {
  max-width: 100%;
  font-size: 0.875rem;

  @include media-breakpoint-down(sm) {
    font-size: 0.813rem;
  }
}
