@import './utils';

// TODO: Come back to later to see if we can remove file.

.bdrs-0 {
  border-radius: 0;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
}

.normal {
  font-weight: normal;
}

.bd-b {
  border-bottom: 1px solid $primary;
}

.link-btn {
  color: $primary;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.no-btn-styles {
  appearance: none;
  border: none;
  background: none;
}

.flex {
  display: flex;
}

.fx-wrap {
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.ds-none {
  display: none !important;
}

.bold {
  font-weight: bold;
}

.ps-relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-2px {
  margin-bottom: 2px;
}

.mah-15 {
  max-height: 15px;
}
